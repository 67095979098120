import { useDataGrid, useDataGridDispatch } from "./context/DataGridContext"
import { useCallback } from "react"
import DataGridHeaderCellFilter from "./DataGridHeaderCellFilter"
import DataGridFreezeColumnButton from "./DataGridFreezeColumnButton"

export default function DataGridHeaderCell({ dragSource, column }) {
  const { activeSort, activeSortDirection, columns, columnWidth } = useDataGrid()
  const dispatch = useDataGridDispatch()
  const idx = columns.findIndex(col => col.key === column.key)
  const { displayName } = column

  const onDragStart = useCallback(event => {
    dragSource.current = event.target.dataset.position
  }, [dragSource])
  const onDragOver = useCallback(event => {
    event.preventDefault()
  }, [])
  const onDrop = useCallback(event => {
    event.preventDefault()
    if (
      !event.target.classList.contains("data-grid-header-cell") || 
      event.target.dataset.position === dragSource.current
    ) return
    dispatch({
      type: "END_DRAG",
      payload: {
        source: parseInt(dragSource.current),
        target: parseInt(event.target.dataset.position)
      }
    })
  }, [dragSource, dispatch])

  function setLeft() {
    return columnWidth.split(" ").slice(0, idx).map(w => parseInt(w.replace("px", ""))).reduce((a, b) => a + b, 0)
  }

  function setRight() {
    return columnWidth.split(" ").slice(idx, -1).map(w => parseInt(w.replace("px", ""))).reduce((a, b) => a + b, 0)
  }

  return (
    <div 
      className="data-grid-header-cell"
      draggable
      onDragStart={onDragStart}
      onDragOver={onDragOver}
      onDrop={onDrop}
      data-key={column.key}
      data-position={idx} 
      style={{
        gridColumnStart: parseInt(idx) + 1,
        position: column.frozen ? "sticky" : undefined,
        left: column.frozen ? setLeft() : undefined,
        right: column.frozen ? setRight() : undefined,
        zIndex: column.frozen ? 1 : undefined,
      }}>
      { displayName }
      {" "}
      { activeSort === column.key ? <>{ activeSortDirection === "asc" ? "▲" : "▼"}</> : <></> }
      {
        (column.frozen || idx === 0 || columns[idx - 1].frozen) && 
        <DataGridFreezeColumnButton column={column} />
      }
      <DataGridHeaderCellFilter column={column} />
    </div>
  )
}
