import { useProfileEditor, useProfileEditorDispatch } from "../../contexts/ProfileEditorContext"
import { useCallback, useMemo } from "react"

/**
 * Renders a line with column name, display name input, freeze checkbox.
 * Relies on profile editor and dispatch.
 * @param {Object} props
 * @param {React.Ref} props.dragSource Ref for currently dragged item.
 * @param {Object} props.column Related column object.
 * @returns {React.JSX.Element}
 */
export default function ColumnSettingsLine({ dragSource, column }) {
  const { editorColumns } = useProfileEditor()
  const dispatch = useProfileEditorDispatch()

  const handleDisplayNameChange = useCallback((event) => {
    event.stopPropagation()
    dispatch({
      type: "UPDATE_COLUMN",
      payload: {
        column: {
          ...column,
          [event.target.dataset.key]: event.target.value  
        }
      }
    })
  }, [dispatch, column])

  const handleFreezeChange = useCallback(event => {
    event.stopPropagation()
    dispatch({
      type: "FREEZE_COLUMN",
      payload: {
        key: column.key,
        setting: event.target.checked
      }
    })
  }, [dispatch, column])

  const onDragStart = useCallback(event => {
    dragSource.current = event.target.classList.contains("columnSettingsLine") ?
      event.target.dataset.position : 
      event.target.parentElement.dataset.position
  }, [dragSource])
  const onDragOver = useCallback(event => {
    event.preventDefault()
  }, [])
  const onDrop = useCallback(event => {
    event.preventDefault()
    const dropTarget = event.target.classList.contains("columnSettingsLine") ?
      event.target : 
      event.target.parentElement
    if (!dropTarget.classList.contains("columnSettingsLine")) return
    if (dropTarget.dataset.position === dragSource.current) return
    dispatch({
      type: "END_DRAG",
      payload: {
        source: parseInt(dragSource.current),
        target: parseInt(dropTarget.dataset.position)
      }
    })
  }, [dragSource, dispatch])

  const colIdx = useMemo(() => Math.max(editorColumns.findIndex(col => col.key === column.key)), [editorColumns, column])
  const frozenIsDisabled = colIdx === 0 ? false : !editorColumns[colIdx - 1].frozen

  return (
    <div 
      className="columnSettingsLine" 
      draggable
      onDragStart={onDragStart}
      onDragOver={onDragOver}
      onDrop={onDrop}
      data-key={column.key}
      data-position={colIdx}>
      <span title="Reorder column">#</span>
      <span>{ column.name }</span>
      <input type="text" onChange={handleDisplayNameChange} data-key="displayName" value={column.displayName}></input>
      <label><input type="checkbox" onChange={handleFreezeChange} data-key="frozen" checked={column.frozen} disabled={frozenIsDisabled}></input></label>
    </div>
  )
}