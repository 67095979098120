import { useCallback } from "react"
import { useDataGrid, useDataGridDispatch } from "../shared/DataGrid/context/DataGridContext"


export default function ScenarioButton({ scenario, category, displayName, filterKey, apply }) {
  const { name } = scenario
  const { filters } = useDataGrid()
  const dispatch = useDataGridDispatch()

  const active = !!filters.find(f => f.category === category && f.values.includes(name) )

  const onClick = useCallback(() => {
    dispatch({
      type: active ? "REMOVE_FILTER" : "ADD_FILTER",
      payload: {
        category: category,
        key: filterKey,
        value: name,
        displayName: displayName,
        apply: apply
      }
    })
  }, [dispatch, name, active, category, filterKey, displayName, apply])

  return (
    <button className={`${active ? "active" : ""}`} onClick={onClick}>
      { name }
    </button>
  )
}
