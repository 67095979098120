import ProfileButton from "../components/ProfileButton"
import FilterContainer from "./FilterContainer"
import AuditBox from "../components/AuditBox"
import OpsLogo from "../assets/OpsLogo.png"

/**
 * Renders Header, AuditBox, ProfileButton, and FilterContainer.
 * @returns {React.JSX.Element}
 */
export default function HeaderContainer() {
  return (
    <header>
      <nav>
        <img src={OpsLogo} height={40} alt="Ops Logo"></img>
        <h3>Ferguson AP on Hold 2.0.1 (SSC)</h3>
        <AuditBox />
        <ProfileButton />
      </nav>
      <FilterContainer />
    </header>
  )
}