import { useCallback, useRef } from "react"
import { useSettings } from "../contexts/SettingsContext"
import { useDataGrid, useDataGridDispatch } from "../shared/DataGrid/context/DataGridContext"

export default function SearchBox() {
  const { columns } = useDataGrid()
  const dispatch = useDataGridDispatch()
  const { groupSettings } = useSettings()
  const { customLookups } = groupSettings

  const select = useRef()
  const input = useRef()

  const onChange = useCallback(() => {
    dispatch({
      type: "UPDATE_SEARCH_FILTER",
      payload: {
        category: "search",
        key: select.current.value,
        values: [input.current.value],
        displayName: select.current.value,
        apply: function(record) {
          const searchValue = input.current.value.toString().toLowerCase()
          const selectValue = select.current.value
          if (searchValue === "") return true
          let options = []
          switch (selectValue) {
            case "ALL":
              options.push(
                ...columns.map(column => record[column.key]),
                record["SEND_COMMENT_TO"],
                customLookups.find(lookup => record[lookup.column] === lookup.value)?.assignTo,
                record["LAST_UPDATED_BY"]
              )
              break
            case "SEND_COMMENT_TO":
              options.push(
                record["SEND_COMMENT_TO"],
                customLookups.find(lookup => record[lookup.column] === lookup.value)?.assignTo
              )
              break
            case "COMMENT_LAST_UPDATED":
              options.push(record["LAST_UPDATED_BY"])
              break
            default: 
              options.push(record[selectValue])
              break
          }
          options = options.map(value => value?.toString().toLowerCase()).filter(value => value)
          return searchValue.startsWith('"') && searchValue.endsWith('"') ?
            options.some(option => option === searchValue.slice(1, searchValue.length - 1)) :
            options.some(option => option.includes(searchValue))
        }
      }
    })
  }, [dispatch, columns, customLookups])

  return (
    <div id="searchBox" className="buttonBox">
      <select ref={select} onChange={onChange}>
        <option value="ALL">ALL</option>
        {
          columns.map(column => <option key={column.key} value={column.key}>{column.displayName}</option>)
        }
      </select>
      <input ref={input} onChange={onChange} type="text"></input>
    </div>
  )
}