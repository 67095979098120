import { useCallback, useRef } from "react"
import { useSettings, useSettingsDispatch } from "../../contexts/SettingsContext"

/**
 * Renders component to set priority thresholds in district configuration.
 * Relies on district settings priority thresholds.
 * @returns {React.JSX.Element}
 */
export default function PriorityThresholds() {
  const { groupSettings } = useSettings()
  const { priorityThresholds } = groupSettings
  const { highPriority, mediumPriority } = priorityThresholds
  const highPriorityGreater = useRef()
  const highPriorityLess = useRef()
  const mediumPriorityGreater = useRef()
  const mediumPriorityLess = useRef()
  const dispatch = useSettingsDispatch()

  // Update priority thresholds in memory
  const onChange = useCallback(() => {
    dispatch({
      type: "SET_PRIORITY_THRESHOLDS",
      payload: {
        highPriority: {
          greaterThanOrEqualTo: highPriorityGreater.current.value,
          lessThanOrEqualTo: highPriorityLess.current.value
        },
        mediumPriority: {
          greaterThanOrEqualTo: mediumPriorityGreater.current.value,
          lessThanOrEqualTo: mediumPriorityLess.current.value
        }
      }
    })
  }, [dispatch])

  return (
    <div id="priorityThresholds">
      <h2>Priority Thresholds</h2>
      <div>
        <div>
          <span>HIGH</span>
        </div>
        <div>
          <span></span>
          <span>Days to Pay</span>
          <span>greater than or equal to</span>
          <input ref={highPriorityGreater} data-priority="highPriority" data-setting="greaterThanOrEqualTo" type="number" onChange={onChange} value={highPriority.greaterThanOrEqualTo}></input>
          <span>days</span>
        </div>
        <div>
          <span></span>
          <span>Days to Pay</span>
          <span>less than or equal to</span>
          <input ref={highPriorityLess} data-priority="highPriority" data-setting="lessThanOrEqualTo" type="number" onChange={onChange} value={highPriority.lessThanOrEqualTo}></input>
          <span>days</span>
        </div>
        <div>
          <span>MEDIUM</span>
        </div>
        <div>
          <span></span>
          <span>Days to Pay</span>
          <span>greater than or equal to</span>
          <input ref={mediumPriorityGreater} data-priority="mediumPriority" data-setting="greaterThanOrEqualTo" type="number" onChange={onChange} value={mediumPriority.greaterThanOrEqualTo}></input>
          <span>days</span>
        </div>
        <div>
          <span></span>
          <span>Days to Pay</span>
          <span>less than or equal to</span>
          <input ref={mediumPriorityLess} data-priority="mediumPriority" data-setting="lessThanOrEqualTo" type="number" onChange={onChange} value={mediumPriority.lessThanOrEqualTo}></input>
          <span>days</span>
        </div>
      </div>
    </div>
  )
}