import { useSettings, useSettingsDispatch } from "../../contexts/SettingsContext"
import { useProfileEditor, useProfileEditorDispatch } from "../../contexts/ProfileEditorContext"
import { useCallback } from "react"
import { useMsal } from "@azure/msal-react"

/**
 * @typedef {Object} Profile
 * @property {string} name Name of profile.
 */

/**
 * Renders line with profile name and edit, set active, and set default buttons.
 * Relies on user settings and dispatch, profile editor and dispatch. 
 * @param {Object} props
 * @param {Profile} props.profile User profile. 
 * @returns {React.JSX.Element}
 */
export default function ColumnProfileLine({ profile }) {
  const { instance, accounts } = useMsal()
  const account = accounts[0]
  const userId = account.idTokenClaims.sub
  const { name } = profile
  const { userSettings } = useSettings()
  const { editorActiveProfile } = useProfileEditor()
  const editorDispatch = useProfileEditorDispatch()
  const settingsDispatch = useSettingsDispatch()
  const { profiles, activeProfile, defaultProfile } = userSettings
  const profileIdx = profiles.findIndex(profile => profile.name === name)

  // Updates profile in editor context
  const onEditClick = useCallback(() => {
    editorDispatch({
      type: "SET_EDITOR_PROFILE",
      payload: profile
    })
  }, [editorDispatch, profile])

  // Sets profile active for current session
  const onSetActiveClick = useCallback(() => {
    settingsDispatch({
      type: "SET_ACTIVE_PROFILE",
      payload: {
        profile: profile
      }
    })
  }, [settingsDispatch, profile])

  // Sets profile default, invokes PATCH request to server
  const onSetDefaultClick = useCallback(async event => {
    const url = process.env.REACT_APP_API.concat(`/user-profiles/${userId}`)
    const { idToken } = await instance.acquireTokenSilent({ account: accounts[0] })
    const response = await fetch(url, {
      method: "PATCH",
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
        "Authorization": `Bearer ${idToken}`
      }, body: JSON.stringify({
        DEFAULT_PROFILE: event.target.dataset.key
      })
    })
    if (response.ok) {
      const { DEFAULT_PROFILE } = await response.json()
      settingsDispatch({
        type: "SET_DEFAULT_PROFILE",
        payload: parseInt(DEFAULT_PROFILE)
      })
    }
  }, [settingsDispatch, accounts, instance, userId])

  return (
    <div className="columnProfileLine">
      <span>{ name }</span>
      {
        editorActiveProfile?.name === name ?
          <span></span> :
          <button onClick={onEditClick}>Edit</button>
      }
      {
        activeProfile.name === name ?
          <span>Active</span> :
          <button onClick={onSetActiveClick}>Set Active</button>
      }
      {
        defaultProfile === profileIdx ?
          <span>User Default</span> :
          <button data-key={profileIdx} onClick={onSetDefaultClick}>Set User Default</button>
      }
    </div>
  )
}