// Default MSAL client configuration.
// Checks session storage for existing sessions.
export const msalConfig = {
  auth: {
    clientId: process.env.REACT_APP_AAD_CLIENT_ID,
    authority: process.env.REACT_APP_AAD_AUTHORITY,
    redirectUri: process.env.REACT_APP_AAD_REDIRECT_URI,
  },
  cache: {
    cacheLocation: "sessionStorage",
    storeAuthStateInCookie: false,
  }
}

// Initial login request object.
export const loginRequest = {
  scopes: [
    "openid"
  ]
}