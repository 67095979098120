import { useSettings } from "../contexts/SettingsContext"
import ModalContainer from "./ModalContainer";



export default function UserSchemaModal({ show }) 

{const { userSchema } = useSettings()

  return (
      
      <ModalContainer show={show} type="userSchema" title="User Schema">
     	 <div className="columnSchemaLine">
        <span> Column Name </span>
          <span> Description </span>
          <span> Purpose </span>
          <span> SampleData   </span>  
        </div>
      {userSchema.defaultSchema.map(row => (
        <div className="columnSchemaLine" key={row.ID} >
                <span>{row.ColumnName}</span>  
                <span>{row.Description}</span>  
                <span>{row.Purpose}</span> 
                <span>{row.SampleData}</span>   
          </div>
      ))}
    
      </ModalContainer>

  )
}