import { useDataGrid, useDataGridDispatch } from "../shared/DataGrid/context/DataGridContext"
import { useCallback } from "react"

export default function ResetSortButton() {
  const { activeSort, activeSortDirection } = useDataGrid()
  const dispatch = useDataGridDispatch()
  const onClick = useCallback(() => {
    if (activeSort === "BRANCH_ID" && activeSortDirection === "des") return
    dispatch({
      type: "SORT_ROWS",
      payload: {
        prop: "BRANCH_ID",
        direction: "des"
      }
    })
  }, [dispatch, activeSort, activeSortDirection])
  return (
    <button onClick={onClick} id="resetSortButton">
      RESET SORT
    </button>
  )
}