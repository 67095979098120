import { useMsal } from "@azure/msal-react"
import ProfileMenu from "./ProfileMenu"

/**
 * Renders a button with the current username, and a ProfileMenu on hover.
 * Relies on MSAL.
 * @returns {React.JSX.Element}
 */
export default function ProfileButton() {
  const { accounts } = useMsal()
  const account = accounts[0]

  return (
    <>
      <div id="profileButton">
        <span>{ account?.username }</span>
        <span>&#9660;</span>
        <ProfileMenu />
      </div>
    </>
  )
}