import DataGridHeaderRow from "./DataGridHeaderRow";
import DataGridRow from "./DataGridRow";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useDataGrid, useDataGridDispatch } from "./context/DataGridContext";
import "./DataGrid.css"
import { useGridDimensions } from "./hooks/useGridDimensions";

export default function DataGrid({ columns, rows }) {
  const { rowHeight, columnWidth, columns: cols, rows: records, filters } = useDataGrid()
  const [ gridRef, gridWidth, gridHeight, ] = useGridDimensions()
  const [ scrollTop, setScrollTop ] = useState(0)
  const [ scrollLeft, setScrollLeft ] = useState(0)
  const dispatch = useDataGridDispatch()

  useEffect(() => {
    dispatch({ type: "INITIALIZE_GRID", data: { columns, rows }})
  }, [dispatch, columns, rows])

  const onScroll = useCallback(event => {
    setScrollTop(event.target.scrollTop)
    setScrollLeft(event.target.scrollLeft)
  }, [])

  const filteredRecords = useMemo(() => filterRecords(records, filters), [records, filters])
  const columnWidths = useMemo(() => cols.map(column => parseInt(column.width.replace("px", ""))), [cols])
  const startIdx = useMemo(() => Math.max(0, Math.floor(scrollTop / rowHeight) - 5), [rowHeight, scrollTop])
  const endIdx = useMemo(() => Math.min(filteredRecords.length - 1, Math.floor((scrollTop + gridHeight) / rowHeight) + 5), [filteredRecords.length, gridHeight, rowHeight, scrollTop])
  const colStartIdx = useMemo(() => getLowestColIdx(columnWidths, scrollLeft), [columnWidths, scrollLeft])
  const colEndIdx = useMemo(() => getHighestColIdx(columnWidths, scrollLeft + gridWidth, colStartIdx), [columnWidths, scrollLeft, gridWidth, colStartIdx])
  
  return (
    <div 
      className="data-grid"
      ref={gridRef}
      onScroll={onScroll}
      style={{
        gridTemplateRows: `50px repeat(${filteredRecords.length}, ${rowHeight}px)`,
        "--dg-col-width": columnWidth
      }}>
      <DataGridHeaderRow colStart={colStartIdx} colEnd={colEndIdx} />
      { 
        getViewportRows(filteredRecords, startIdx, endIdx, colStartIdx, colEndIdx)
      }
    </div>
  )
}

function getLowestColIdx(columnWidths, scrollLeft) {
  let idx = 0, sum = 0
  if (scrollLeft < columnWidths[0]) return 0
  while (sum < scrollLeft - 300) {
    sum += columnWidths[idx]
    idx++
  }
  return idx
}

function getHighestColIdx(columnWidths, totalLimit, startIdx) {
  let idx = startIdx, sum = columnWidths.slice(0, startIdx).reduce((a, b) => a + b, 0)
  while (sum < totalLimit) {
    if (idx >= columnWidths.length - 1) break
    sum += columnWidths[idx]
    idx++
  }
  return idx
}

function getViewportRows(rows, startIdx, endIdx, colStartIdx, colEndIdx) {
  const viewportRows = []
  for (let i = startIdx; i <= endIdx; i++) {
    viewportRows.push(<DataGridRow key={rows[i].id} record={rows[i]} position={i + 2} colStart={colStartIdx} colEnd={colEndIdx} />)
  }
  return viewportRows
}

function filterRecords(records, filters) {
  return records.filter(record => filters.every(filter => filter.apply(record, filter.values)))
}
