import { useFetch } from "../hooks/useFetch"
import { useMemo } from "react"
import { get } from "../lib/api"
import { useDataGrid } from "../shared/DataGrid/context/DataGridContext"

/**
 * Renders a box with the last refresh date and current filtered row count.
 * Relies on DataGrid rows and filters.
 * @returns {React.JSX.Element}
 */
export default function AuditBox() {
  const { value, isLoading } = useFetch(get, "audit/last-refresh")
  const { rows, filters } = useDataGrid()
  const lastRefresh = useMemo(() => value && new Date(value.loadDatetime).toLocaleString(), [value])
  const rowCount = useMemo(() => rows.filter(row => filters.every(filter => filter.apply(row, filter.values))).length, [rows, filters])

  return (
    <div id="auditBox">
      <div>Last Refresh: { isLoading ? "" : lastRefresh }</div>
      <div>Row Count {`(after filters)`}: { rowCount }</div>
    </div>
  )
}