import { ReactComponent as FergusonLogo } from "../assets/Ferguson_RGB.svg"

/**
 * Notifies user of no access, provides steps to request access.
 * @returns {React.JSX.Element}
 */
export default function NoAccess() {
  return (
    <div id="noAccessPage">
      <FergusonLogo height={150} />
      <h1>AP on Hold (SSC)</h1>
      <h3>UNAUTHORIZED</h3>
      <p>
        To request access to AP on Hold, please submit this {" "}
        <a href="https://forms.office.com/Pages/ResponsePage.aspx?id=NYQvPEyZUkWP6CrsLQgi5M8AGMKHZAtJokrw1A9W3HZUQkdaWVJUMzJZWTdWWTZZTjVIQzNSM0kwSS4u">
          form
        </a>.
      </p>
    </div>
  )
}