import useWebSocket from "react-use-websocket"
import Home from './pages/Home';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css'
import { DataGridProvider } from './shared/DataGrid/context/DataGridContext';
import { SettingsProvider } from './contexts/SettingsContext';
import { useMsal } from "@azure/msal-react";
import { useMemo } from "react";
import NoAccess from "./pages/NoAccess";
import { GroupsProvider } from "./contexts/GroupsContext";

/**
 * Central App component.
 * Provides Settings, Districts, and DataGrid contexts to all children components
 * @returns {React.JSX.Element}
 */
function App() {
  // Verify app permissions
  const { accounts } = useMsal()
  const { roles } = accounts[0].idTokenClaims
  const hasPermission = useMemo(() => roles?.some(role => {
    return role.startsWith("APonHold.") && 
    (role.endsWith(".Contributor") || role.endsWith(".Administrator"))
  }), [roles])

  // Create reusable web socket connection w/ server
  useWebSocket(process.env.REACT_APP_WS_URL, {
    share: true,
    filter: () => false,
    retryOnError: true,
    shouldReconnect: () => true
  })

  return (
      <SettingsProvider>
        <GroupsProvider>
          <DataGridProvider>
            <div className="App">
              { hasPermission ? <Home /> : <NoAccess /> }
            </div>
          </DataGridProvider>
        </GroupsProvider>
      </SettingsProvider>
  );
}

export default App
