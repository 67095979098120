import { useCallback, useEffect, useState, useMemo } from "react"
import { useSettings } from "../../contexts/SettingsContext"

export default function DataGridEditableCellSelectInput({ record, column, handler }) {
  const [ val, setVal ] = useState(record[column.key] || "")
  const { groupSettings } = useSettings()
  const { customLookups, branchActions } = groupSettings

  const lookup = useMemo(() => customLookups.find(lookup => record[lookup.column] === lookup.value), [customLookups, record])
  useEffect(() => {
    if (record[column.key] && record[column.key] !== "") {
      setVal(record[column.key])
    } else if (lookup) {
      setVal(lookup.assignTo)
    }
  }, [record, column, lookup])

  const onChange = useCallback(event => {
    event.stopPropagation()
    handler(event)
    setVal(event.target.value)
  }, [handler])

  let options

  if (column.key === "BRANCH_ACTION") {
    options = branchActions
  } else if (column.key === "SEND_COMMENT_TO") {
    options = Array.from(new Set(customLookups.map(lookup => lookup.assignTo)))
  } else {
    options = []
  }

  return (
    <select data-key={column.key} onChange={onChange} className="dg-select-cell" value={val}>
      <option key="blank" value=""></option>
      {options.map(option => <option key={option} value={option}>{option}</option>)}
    </select>
  )
}