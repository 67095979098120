import DataGridHeaderCell from "./DataGridHeaderCell";
import { useDataGrid } from "./context/DataGridContext";
import { useRef } from "react";

export default function DataGridHeaderRow({ colStart, colEnd }) {
  const { columns } = useDataGrid()
  const dragSource = useRef()
  return (
    <div className="data-grid-header-row" style={{gridTemplateColumns: "var(--dg-col-width)"}}>
      { 
        columns.length > 1 && getViewportColumns(columns, dragSource, colStart, colEnd)
      }
    </div>
  )
}

function getViewportColumns(columns, dragSource, startIdx, endIdx) {
  const viewportColumns = []
  for (let i = startIdx; i <= endIdx; i++) {
    const column = columns[i]
    if (!column.frozen) viewportColumns.push(<DataGridHeaderCell key={column.key} dragSource={dragSource} column={column} />)
  }
  const frozenColumns = columns.filter(column => column.frozen).map(column => <DataGridHeaderCell key={column.key} dragSource={dragSource} column={column} />)
  return [...frozenColumns, ...viewportColumns]
}
