import DistrictBox from "../components/DistrictBox"
import AreaBox from "../components/AreaBox"
import ScenarioBox from "../components/ScenarioBox"
import PriorityBox from "../components/PriorityBox"
import POTypeBox from "../components/POTypeBox"
import FilterBox from "../components/FilterBox"
import SearchBox from "../components/SearchBox"
import { useDataGrid } from "../shared/DataGrid/context/DataGridContext"
import ResetFiltersButton from "../components/ResetFiltersButton"
import ResetSortButton from "../components/ResetSortButton"

/**
 * Relies on DataGridContext.
 * Renders AreaBox, PriorityBox, ScenarioBox, POTypeBox, SearchBox, and conditional ResetFiltersButton, ResetSortButton.
 * @returns {React.JSX.Element}
 */
export default function FilterContainer() {
  const { filters, activeSort } = useDataGrid()

  return (
    <div className="filterContainer">
      <FilterBox title="DISTRICT"><DistrictBox /></FilterBox>
      <div className="filterContainer">
        <div id="filtersHeader">FILTERS</div>
        <FilterBox title="AREAS"><AreaBox /></FilterBox>
        <FilterBox title="PRIORITY"><PriorityBox /></FilterBox>
        <FilterBox title="SCENARIOS"><ScenarioBox /></FilterBox>
        <FilterBox title="PO TYPES"><POTypeBox /></FilterBox>
        <FilterBox title="SEARCH"><SearchBox /></FilterBox>
        {
          // If more than 1 filter is applied (or 1 if it is not a search filter), 
          // OR if the activeSort is not on Branch ID
          (
            filters.length > 1 || 
            (filters.length === 1 && filters[0].category !== "search") ||
            activeSort !== "BRANCH_ID"
          ) && 
          <FilterBox title="" className="buttonBox">
            <ResetFiltersButton />
            <ResetSortButton />
          </FilterBox>
        }
      </div>  
    </div>
  )
}