import { createContext, useContext, useReducer } from "react"

const ProfileEditorContext = createContext(null)
const ProfileEditorDispatchContext = createContext(null)

export function useProfileEditor() {
  return useContext(ProfileEditorContext)
}

export function useProfileEditorDispatch() {
  return useContext(ProfileEditorDispatchContext)
}

/**
 * Provides Districts store context to child components.
 * @param {Object} obj
 * @param {FunctionComponentElement} obj.children
 * @returns {React.Context.Provider} 
 */
export function ProfileEditorProvider({ children }) {
  const [ profileEditor, dispatch ] = useReducer(profileEditorReducer, initialState)

  return (
    <ProfileEditorContext.Provider value={profileEditor}>
      <ProfileEditorDispatchContext.Provider value={dispatch}>
        { children }
      </ProfileEditorDispatchContext.Provider>
    </ProfileEditorContext.Provider>
  )
}

/**
 * Creates new state objects based on incoming action.
 * @param {Object} state Existing state object.
 * @param {string} action Pre-defined action type.
 * @returns {Object} New state object.
 */
function profileEditorReducer(state, action) {
  switch(action.type) {
    case "INITIALIZE_EDITOR":
      return {
        ...state,
        editorActiveProfile: action.payload.editorActiveProfile,
        editorColumns: action.payload.editorColumns
      }
    case "SET_EDITOR_PROFILE":
      return {
        ...state,
        editorActiveProfile: action.payload,
        editorColumns: action.payload.columns
      }
    case "UPDATE_COLUMN":
      return {
        ...state,
        editorColumns: state.editorColumns.map(column => {
          if (column.key === action.payload.column.key) {
            return { ...column, ...action.payload.column }
          } else {
            return column
          }
        })
      }
    case "END_DRAG":
      const columns = state.editorColumns.map(column => {
        return {
          ...column,
          frozen: false
        }
      })
      return {
        ...state,
        editorColumns: _moveArrayItem(columns, action.payload.source, action.payload.target)
      }
    case "FREEZE_COLUMN":
      const { key, setting } = action.payload
      const idx = state.editorColumns.findIndex(column => column.key === key)
      return {
        ...state,
        editorColumns: state.editorColumns.map((column, colIdx) => {
          if ((idx === colIdx) || (!setting && idx <= colIdx)) {
            return {
              ...column,
              frozen: setting
            }
          } else {
            return column
          }
        })
      }
    default:
      return state
  }
}

const initialState = {
  editorActiveProfile: null,
  editorColumns: []
}

/**
 * Reorders array.
 * @param {[]} array Source array.
 * @param {number} from Source index.
 * @param {number} to Destination index.
 * @returns {[]} New array, with modified order.
 */
function _moveArrayItem(array, from, to) {
  return (array.slice(), array.splice(to, 0, ...array.splice(from, 1)), array)
}