import { useCallback } from "react"
import { useSettings, useSettingsDispatch } from "../../contexts/SettingsContext"
import CustomAssignmentLine from "./CustomAssignmentLine"

/**
 * Component for all CustomAssignmentLines and an Add button.
 * Relies on district settings custom lookups.
 * @returns {React.JSX.Element}
 */
export default function CustomAssignments() {
  const { groupSettings } = useSettings()
  const { customLookups } = groupSettings
  const dispatch = useSettingsDispatch()

  // Create blank custom assignment in memory, defaulting to Branch ID
  const onClick = useCallback(() => {
    dispatch({
      type: "ADD_CUSTOM_ASSIGNMENT",
      payload: {
        column: "BRANCH_ID",
        value: "",
        assignTo: ""
      }
    })    
  }, [dispatch])

  return (
    <div id="customAssignments">
      <h2>Custom Assignments</h2>
      <div id="customAssignmentsContainer">
        <div className="customAssignmentLine">
          <span>Column</span>
          <span>Value</span>
          <span>Assign To</span>
        </div>
        {
          customLookups.map((lookup, idx) => <CustomAssignmentLine key={`${lookup.column}-${lookup.value}`} idx={idx} customAssignment={lookup} />)
        }
      </div>
      <button onClick={onClick}>Add</button>
    </div>
  )
}