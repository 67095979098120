import { useMsal } from "@azure/msal-react"
import { useCallback } from "react"
import { useDataGrid, useDataGridDispatch } from "./context/DataGridContext"
import useWebSocket from "react-use-websocket"
import DataGridEditableCellSelectInput from "./DataGridEditableCellSelectInput"
import DataGridEditableCellTextInput from "./DataGridEditableCellTextInput"
import { put, post } from "../../lib/api"
import { useGroups } from "../../contexts/GroupsContext"

export default function DataGridEditableCell({ record, column, columnIdx, isActive, color }) {
  const { instance, accounts } = useMsal()
  const { activeDistrict } = useGroups()
  const dispatch = useDataGridDispatch()
  const { rows, columnWidth } = useDataGrid()

  const { sendJsonMessage } = useWebSocket(process.env.REACT_APP_WS_URL, {
    share: true
  })

  const onChange = useCallback(async event => {
    if (record[event.target.dataset.key] === event.target.value) return
    if (record[event.target.dataset.key] === undefined && event.target.value === "") return
    const account = accounts[0]
    const { idToken } = await instance.acquireTokenSilent({ account })
    const {
      COMMENT_LAST_READ,
      COMMENT_ID
    } = record

    const request = JSON.stringify({
      LAST_UPDATED_BY: account.idTokenClaims.name,
      DISTRICT: activeDistrict.shortName,
      RECORD_ID: record.id,
      lastRead: COMMENT_LAST_READ,
      [event.target.dataset.key]: event.target.value
    })

    let response = await (
      COMMENT_ID ? 
        put(`user-comments/${COMMENT_ID}`, idToken, request) : 
        post("user-comments", idToken, request)
    )
    const json = await response.json()
    // If Error, Popover Error
    if (json.error) {
      console.error(json.error)
    } else {
      dispatch({
        type: "UPDATE_RECORD",
        payload: {
          id: record.id,
          LAST_UPDATED_BY: json.LAST_UPDATED_BY,
          COMMENT_LAST_UPDATED: json._ts,
          COMMENT_LAST_READ: Math.floor(Date.now() / 1000),
          COMMENT_ID: json.id,
          [event.target.dataset.key]: json[event.target.dataset.key]
        }
      })
    }
  }, [accounts, instance, record, activeDistrict, dispatch])

  const onFocus = useCallback(() => {
    sendJsonMessage({
      type: "addFocus",
      sub: accounts[0]?.username,
      value: record.id
    })
  }, [sendJsonMessage, record.id, accounts])

  const onBlur = useCallback(() => {
    sendJsonMessage({
      type: "removeFocus",
      sub: accounts[0]?.username,
      value: record.id
    })
  }, [sendJsonMessage, record.id, accounts])

  function setLeft() {
    return columnWidth.split(" ").slice(0, columnIdx).map(w => parseInt(w.replace("px", ""))).reduce((a, b) => a + b, 0)
  }

  function setRight() {
    return columnWidth.split(" ").slice(columnIdx, -1).map(w => parseInt(w.replace("px", ""))).reduce((a, b) => a + b, 0)
  }

  return (
    <div
      data-position={columnIdx}
      className="data-grid-cell" 
      onFocus={onFocus}
      onBlur={onBlur}
      style={{
        backgroundColor: isActive ? color : undefined,
        gridColumnStart: columnIdx + 1,
        position: column.frozen ? "sticky" : undefined,
        left: column.frozen ? setLeft() : undefined,
        right: column.frozen ? setRight() : undefined
        }}
    >
      {
        column.editableType === "select" ?
          <DataGridEditableCellSelectInput value={record[column.key]} record={record} column={column} handler={onChange}  /> :
          <DataGridEditableCellTextInput value={rows.find(r => r.id === record.id)[column.key]} columnKey={column.key} handler={onChange} />
      }
    </div>
  )
}
