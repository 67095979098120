const API = process.env.REACT_APP_API
const HEADERS = {
  "Accept": "application/json",
  "Content-Type": "application/json"
}

/**
 * Invokes a GET request to designated endpoint.
 * Applies token as Bearer authorization header.
 * @param {string} endpoint API path.
 * @param {string} token JWT.
 * @returns {Promise<Response>} Fetch response.
 */
export async function get(endpoint, token) {
  const response = await fetch(`${API}/${endpoint}`, {
    headers: {
      ...HEADERS,
      "Authorization": `Bearer ${token}`
    }
  })
  return response
}

/**
 * Invokes a POST request to designated endpoint.
 * Applies token as a Bearer authorization header.
 * @param {string} endpoint API path.
 * @param {string} token JWT.
 * @param {string} data Fetch payload.
 * @returns {Promise<Response>} Fetch response.
 */
export async function post(endpoint, token, data) {
  if (!data || data === "{}") throw new Error()
  const response = await fetch(`${API}/${endpoint}`, {
    method: "POST",
    headers: {
      ...HEADERS,
      "Authorization": `Bearer ${token}`
    }, body: data
  })
  return response
}

/**
 * Invokes a PUT request to designated endpoint.
 * Applies token as a Bearer authorization header.
 * @param {string} endpoint API path.
 * @param {string} token JWT.
 * @param {string} data Fetch payload.
 * @returns {Promise<Response>} Fetch response.
 */
export async function put(endpoint, token, data) {
  if (!data || data === "{}") throw new Error()
  const response = await fetch(`${API}/${endpoint}`, {
    method: "PUT",
    headers: {
      ...HEADERS,
      "Authorization": `Bearer ${token}`
    }, body: data
  })
  return response
}
