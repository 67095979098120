import { useDataGrid } from "./context/DataGridContext"

export default function DataGridCell({ record, column, columnIdx, isActive, color }) {
  const { columnWidth } = useDataGrid()

  function setLeft() {
    return columnWidth.split(" ").slice(0, columnIdx).map(w => parseInt(w.replace("px", ""))).reduce((a, b) => a + b, 0)
  }

  function setRight() {
    return columnWidth.split(" ").slice(columnIdx, -1).map(w => parseInt(w.replace("px", ""))).reduce((a, b) => a + b, 0)
  }

  let content = ""
  if (column.key === "COMMENT_LAST_UPDATED") {
    const { COMMENT_LAST_UPDATED, LAST_UPDATED_BY } = record
    if (COMMENT_LAST_UPDATED) content = `${new Date(COMMENT_LAST_UPDATED * 1000).toLocaleString()}`
    if (COMMENT_LAST_UPDATED && LAST_UPDATED_BY) content += ` - ${LAST_UPDATED_BY}`
  } else if (["DISC_AMT", "TTL_RCVG_AMT", "INV_TOTAL_AMT", "INV_FRT_AMT"].includes(column.key)) {
    content = record[column.key] !== null ? `$${parseFloat(record[column.key]).toFixed(2)}` : "-"
  } else {
    content = record[column.key]
  }

  return (
    <div 
      data-position={columnIdx} 
      className="data-grid-cell" 
      style={{
        backgroundColor: isActive ? color : undefined,
        gridColumnStart: columnIdx + 1,
        position: column.frozen ? "sticky" : undefined,
        left: column.frozen ? setLeft() : undefined,
        right: column.frozen ? setRight() : undefined,
      }}
    >
      { content !== undefined ? content : "" }
    </div>
  )
}
