import { useEffect, useState } from "react"

export default function DataGridEditableCellTextInput({ columnKey, value, handler }) { 
  const [ val, setVal ] = useState(value || "")
  useEffect(() => {
    setVal(value || "")
  }, [value])
  return (
    <input data-key={columnKey} className="dg-text-cell" type="text" onChange={e => setVal(e.target.value)} onBlur={handler} value={val}></input>
  )
}