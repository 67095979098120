import { useMsal } from "@azure/msal-react"
import { useCallback, useMemo } from "react"
import { useSettingsDispatch } from "../contexts/SettingsContext"
import { useGroups } from "../contexts/GroupsContext"
import { useDataGrid } from "../shared/DataGrid/context/DataGridContext"

export default function ProfileMenu() {
  const { groups, activeDistrict } = useGroups()
  const { columns, rows, filters } = useDataGrid()
  const group = useMemo(() => groups.find(group => group.districts.some(district => district.shortName === activeDistrict?.shortName)), [groups, activeDistrict?.shortName])
  const { accounts } = useMsal()
  const account = accounts[0]
  const { roles } = account.idTokenClaims

  const hasPermission = useMemo(() => roles?.some(role => role.endsWith(`${group?.role}.Administrator`)), [roles, group?.role])
  const filteredRecords = useMemo(() => rows.filter(row => filters.every(filter => filter.apply(row, filter.values))), [rows, filters])

  const dispatch = useSettingsDispatch() 
  const onClick = useCallback(event => {
    event.stopPropagation()
    dispatch({
      type: "TOGGLE_MODAL",
      modal: event.target.dataset.key
    })
  }, [dispatch])

  const onExportClick = useCallback(event => {
    event.stopPropagation()
    let content = ""
    const headers = columns.map(column => column.displayName)
    content += headers.join(",")
    content += "\r\n"
    for (const record of filteredRecords) {
      let rowContent = ""
      for (const column of columns) {
        rowContent += `"${record[column.key] !== undefined && record[column.key] !== null ? record[column.key].toString().replace(/"/g, "") : ''}",`
      }
      rowContent += "\r\n"
      content += rowContent
    }
    const blob = new Blob([content], { type: "text/csv" })
    const url = window.URL.createObjectURL(blob)
    const link = document.createElement("a")
    link.setAttribute("href", url)
    link.setAttribute("download", `${activeDistrict.shortName}_${new Date().toLocaleDateString().replace(/ /g, "_")}.csv`)
    link.style.top = "-1000px"
    document.body.appendChild(link)
    link.click()
    link.remove()
  }, [columns, activeDistrict?.shortName, filteredRecords])



  return (
    <div className="dropdownMenu">
      <button type="button" onClick={onClick} data-key={"userSettings"}>User Settings</button>
      {
        hasPermission &&
        <button type="button" onClick={onClick} data-key={"groupSettings"}>Group Settings</button>
      }
      <button type="button" onClick={onClick} data-key={"userSchema"}>Application Schema</button>
      <button type="button" onClick={onExportClick}>Export Rows to CSV</button>
      <hr />
      <span
      style={{
        fontWeight: "normal",
        fontSize: "smaller",
        color: "#aaa",
      }}>Build: {`${process.env.REACT_APP_BUILD_NO}`}</span>
    </div>
  )
}