import { useCallback, useState } from "react";
import Button from "react-bootstrap/Button"
import Modal from "react-bootstrap/Modal"
import { useMsal } from "@azure/msal-react";
import { useSettings } from "../../contexts/SettingsContext";
import { useDataGridDispatch } from "../../shared/DataGrid/context/DataGridContext";
import { useGroups, useGroupsDispatch } from "../../contexts/GroupsContext";

export default function SaveGroupSettingsButton() {
  const [ saveShow, setSaveShow ] = useState(false)
  const [ success, setSuccess ] = useState(false)
  const { instance, accounts } = useMsal()
  const { groups, activeDistrict } = useGroups()
  const { groupSettings } = useSettings()
  const { modalIsActive, ...config } = groupSettings
  const groupsDispatch = useGroupsDispatch()
  const dataGridDispatch = useDataGridDispatch()

  // Update district configuration in database, render modal on finish
  const onClick = useCallback(async () => {
    const group = groups.find(group => group.districts.some(district => district.shortName === activeDistrict?.shortName))
    const url = process.env.REACT_APP_API.concat(`/groups/${group.role}`)
    const { idToken } = await instance.acquireTokenSilent({ account: accounts[0] })
    const response = await fetch(url, {
      method: "PATCH",
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
        "Authorization": `Bearer ${idToken}`
      }, body: JSON.stringify(config)
    })
    setSuccess(response.ok)
    setSaveShow(true)
    if (response.ok) {
      // APPLY CUSTOM LOOKUPS
      const json = await response.json()
      groupsDispatch({
        type: "UPDATE_GROUP_CONFIG",
        payload: json
      })
      dataGridDispatch({
        type: "APPLY_CUSTOM_LOOKUPS",
        payload: config.customLookups
      })
    }
  }, [accounts, groups, activeDistrict?.shortName, instance, config, dataGridDispatch, groupsDispatch])

  return (
    <>
      <Button onClick={onClick} variant="success">
        Save Changes
      </Button>
      <Modal
        size="xs"
        show={saveShow}
        onHide={() => setSaveShow(false)}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            { 
              success ? "Success" : "Error"
            }
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {
            success ? 
              "Group settings saved successfully!" :
              "Error saving group settings."
          }
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => setSaveShow(false)}>Close</Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}