import { useCallback, useMemo, useState } from "react";
import { useSettings, useSettingsDispatch } from "../../contexts/SettingsContext";
import { useProfileEditor, useProfileEditorDispatch } from "../../contexts/ProfileEditorContext";
import { useMsal } from "@azure/msal-react";
import Modal from "react-bootstrap/Modal"
import Button from "react-bootstrap/Button"

/**
 * Renders Save, Save as New, Delete profile buttons, related conditional modals.
 * Relies on Settings and dispatch, ProfileEditor and dispatch, MSAL.
 * @returns {React.JSX.Element}
 */
export default function ColumnSettingsButtons() {
  const { instance, accounts } = useMsal()
  const [ saveAsNewShow, setSaveAsNewShow ] = useState(false)
  const [ deleteProfileShow, setDeleteProfileShow ] = useState(false)
  const [ saveProfileShow, setSaveProfileShow ] = useState(false)
  const [ saveAsNewProfileName, setSaveAsNewProfileName ] = useState("")
  const [ confirmEnabled, setConfirmEnabled ] = useState(true)
  const { editorActiveProfile, editorColumns } = useProfileEditor()
  const { userSettings } = useSettings()
  const { profiles } = userSettings
  const dispatch = useSettingsDispatch()
  const profileEditorDispatch = useProfileEditorDispatch()
  const userId = accounts[0].idTokenClaims.sub
  const editorActiveProfileName = useMemo(() => editorActiveProfile?.name, [editorActiveProfile])

  const onNewProfileNameChange = useCallback(event => {
    setSaveAsNewProfileName(event.target.value)
    const isValid = profiles.map(profile => profile.name.toLowerCase()).includes(event.target.value.toLowerCase())
    event.target.classList.toggle("invalid-input", isValid)
    setConfirmEnabled(isValid)
  }, [profiles])

  const onSaveAsNewConfirmClick = useCallback(async () => {
    const url = process.env.REACT_APP_API.concat(`/user-profiles/${userId}`)
    const request = { account: accounts[0] }
    const { idToken } = await instance.acquireTokenSilent(request)
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
        "Authorization": `Bearer ${idToken}`
      }, body: JSON.stringify({
          profile: {
            name: saveAsNewProfileName,
            columns: editorColumns    
          }
      })
    })
    if (response.ok) {
      const { PROFILES } = await response.json()
      dispatch({
        type: "CREATE_PROFILE",
        payload: PROFILES
      })
      setSaveAsNewShow(false)
      setSaveAsNewProfileName("")
      profileEditorDispatch({
        type: "SET_EDITOR_PROFILE",
        payload: userSettings.profiles[userSettings.profiles.length - 1]
      })
    }
  }, [userId, instance, accounts, saveAsNewProfileName, editorColumns, dispatch, userSettings, profileEditorDispatch])

  const onSaveProfileClick = useCallback(async () => {
    const url = process.env.REACT_APP_API.concat(`/user-profiles/${userId}/${editorActiveProfileName}`)
    const request = { account: accounts[0] }
    const { idToken } = await instance.acquireTokenSilent(request)
    const response = await fetch(url, {
      method: "PATCH",
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
        "Authorization": `Bearer ${idToken}`
      }, body: JSON.stringify({
        profile: {
          name: editorActiveProfileName,
          columns: editorColumns
        }
      })
    })
    if (response.ok) {
      const { PROFILES } = await response.json()
      dispatch({
        type: "UPDATE_PROFILE",
        payload: PROFILES
      })
      setSaveProfileShow(true)
    }
  }, [accounts, dispatch, editorActiveProfileName, editorColumns, instance, userId])

  const onDeleteProfileClick = useCallback(async () => {
    const url = process.env.REACT_APP_API.concat(`/user-profiles/${userId}/${editorActiveProfileName}`)
    const request = { account: accounts[0] }
    const { idToken } = await instance.acquireTokenSilent(request)
    const response = await fetch(url, {
      method: "DELETE",
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
        "Authorization": `Bearer ${idToken}`
      }
    })
    if (response.ok) {
      const { PROFILES } = await response.json()
      dispatch({
        type: "DELETE_PROFILE",
        payload: PROFILES
      })
      setDeleteProfileShow(false)
      profileEditorDispatch({
        type: "SET_EDITOR_PROFILE",
        payload: userSettings.profiles[0]
      })
    }
  }, [accounts, dispatch, profileEditorDispatch, editorActiveProfileName, instance, userId, userSettings])
 
  return (
    <div className="columnSettingsButtons">
      {
        editorActiveProfile?.name === "default" ?
          <button onClick={() => setSaveAsNewShow(true)}>Save as New Profile</button> :
          <>
            <button onClick={onSaveProfileClick}>Save Profile</button>
            <button onClick={() => setSaveAsNewShow(true)}>Save as New Profile</button>
            <button onClick={() => setDeleteProfileShow(true)}>Delete Profile</button>
          </>
      }
      
      {/* SAVE AS NEW */}
      <Modal
        size="xs"
        aria-labelledby="contained-modal-title-vcenter"
        show={saveAsNewShow}
        onHide={() => { setSaveAsNewShow(false); setSaveAsNewProfileName("") }}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Save New Profile
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <label id="saveAsNewProfileName">
            Enter New Profile Name
            <input type="text" onChange={onNewProfileNameChange} defaultValue={saveAsNewProfileName} />
          </label>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={onSaveAsNewConfirmClick} disabled={confirmEnabled} variant="success">Confirm</Button>
          <Button onClick={() => { setSaveAsNewShow(false); setSaveAsNewProfileName("") }}>Cancel</Button>
        </Modal.Footer>
      </Modal>

      {/* DELETE PROFILE */}
      <Modal
        size="xs"
        aria-labelledby="contained-modal-title-vcenter"
        show={deleteProfileShow}
        onHide={() => setDeleteProfileShow(false)}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Delete Profile "{ editorActiveProfile?.name }"
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            Are you sure you want to delete this profile?
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={onDeleteProfileClick} variant="danger">Delete</Button>
          <Button onClick={() => setDeleteProfileShow(false)}>Cancel</Button>
        </Modal.Footer>
      </Modal>

      {/* SAVE PROFILE */}
      <Modal
        size="xs"
        aria-labelledby="contained-modal-title-vcenter"
        show={saveProfileShow}
        onHide={() => setSaveProfileShow(false)}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Success!
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          { editorActiveProfileName } updated successfully.
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => setSaveProfileShow(false)}>Close</Button>
        </Modal.Footer>
      </Modal>

    </div>
  )
}