import Modal from "react-bootstrap/Modal"
import { useSettingsDispatch } from "../contexts/SettingsContext"

/**
 * Reusable Modal component. 
 * @param {Object} obj
 * @param {React.JSX.Element} obj.children Child elements.
 * @param {string} obj.title Title of modal.
 * @param {React.JSX.Element} obj.footer Child elements, to be placed in footer.
 * @param {boolean} obj.show Whether or not to render the modal.
 * @param {string} obj.type Type of modal, used for settings dispatch. 
 * @returns 
 */
export default function ModalContainer({ children, title, footer, show, type }) {
  const dispatch = useSettingsDispatch()
  return (
    <Modal
      backdrop="static"
      keyboard="false"
      show={show}
      onHide={() => dispatch({ type: "TOGGLE_MODAL", modal: type })}
      contentClassName="ap-modal"
      size="xl"
      centered={true}
    >
      <Modal.Header closeButton={true}>
        <Modal.Title>{ title }</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        { children }
      </Modal.Body>
      <Modal.Footer>
        { footer }
      </Modal.Footer>
    </Modal>
  )
}