import HeaderContainer from "../containers/HeaderContainer";
import RecordContainer from "../containers/RecordContainer";
import UserSettingsModal from "../containers/UserSettingsModal";
import UserSchemaModal from "../containers/UserSchemaModal";
import { useSettings, useSettingsDispatch } from "../contexts/SettingsContext";
import { useFetch } from "../hooks/useFetch";
import { get } from "../lib/api";
import { useEffect } from "react";
import { useGroupsDispatch } from "../contexts/GroupsContext";
import Spinner from "react-bootstrap/esm/Spinner";
import GroupSettingsModal from "../containers/GroupSettingsModal";

/**
 * Default application home page.
 * Renders HeaderContainer, RecordContainer.
 * Conditionally renders UserSettingsModal, DistrictSettingsModal.
 * @returns {React.JSX.Element}
 */
export default function Home() {
  const { userSettings, groupSettings, userSchema } = useSettings()
  const groupsDispatch = useGroupsDispatch()
  const settingsDispatch = useSettingsDispatch()
  const { value, isLoading } = useFetch(get, "groups")
  useEffect(() => {
    if (value) {
      groupsDispatch({
        type: "SET_GROUPS",
        payload: value
      })
      settingsDispatch({
        type: "LOAD_GROUP_SETTINGS",
        payload: value[0].config
      })
    } 
  }, [groupsDispatch, settingsDispatch, value])
  return (
    isLoading ?
      <div className="loading" style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "calc(100vh - 200px)"
      }}>
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div> :
      <>
        <HeaderContainer />
        <main>
          { <UserSchemaModal show={userSchema.modalIsActive} /> }
          { <UserSettingsModal show={userSettings.modalIsActive} /> }
          { <GroupSettingsModal show={groupSettings.modalIsActive} /> }
          <RecordContainer />
        </main>
      </>
  )
}