/**
 * 
 * @param {Object} obj 
 * @returns 
 */
export default function FilterBox({ children, title, className }) {
  return (
    <div className={`filterBox ${className ? className : ""}`}>
      <span>{ title }</span>
      { children }
    </div>
  )
}