import { useCallback, useState } from "react"
import DataGridHeaderCellFilterOptions from "./DataGridHeaderCellFilterOptions"
import { ReactComponent as Filter } from "./assets/filter.svg"

export default function DataGridHeaderCellFilter({ column }) {
  const [isActive, setIsActive] = useState(false)

  const onClick = useCallback(event => {
    event.stopPropagation()
    if (event.target.tagName !== "DIV") return
    setIsActive(!isActive)
  }, [isActive])

  const onMouseLeave = useCallback(() => {
    setTimeout(() => setIsActive(false), 100)
  }, [])

  return (
    <div
      title="Filter column" 
      className="data-grid-header-cell-filter dg-hc-b"
      onClick={onClick}>
      <Filter height={12} width={12} />
      { 
        isActive && <DataGridHeaderCellFilterOptions column={column} onMouseLeave={onMouseLeave} />
      }
    </div>
  )
}
