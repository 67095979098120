import { useDataGridDispatch } from "./context/DataGridContext"
import { useCallback } from "react"
import { ReactComponent as Lock } from "./assets/lock.svg"
import { ReactComponent as Unlock } from "./assets/unlock.svg"

export default function DataGridFreezeColumnButton({ column }) {
  const dispatch = useDataGridDispatch()
  const { key, frozen } = column
  const onClick = useCallback((event) => {
    event.stopPropagation()
    if (event.target.tagName !== "SPAN") return
    dispatch({
      type: "FREEZE_COLUMN",
      payload: {
        key: key,
        setting: !frozen
      }
    })
  }, [dispatch, key, frozen])
  return (
    <span onClick={onClick} data-key={key} className="dg-fc-b" title={frozen ? "Unfreeze column" : "Freeze column"}>
      { frozen ? <Lock height={12} width={12} /> : <Unlock height={12} width={12} /> }
    </span>
  )
}