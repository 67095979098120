import { useCallback } from "react"
import { useSettingsDispatch } from "../../contexts/SettingsContext"

/**
 * Renders a branch action and a related delete button.
 * @param {Object} props
 * @param {string} props.branchAction Branch action text. 
 * @returns {React.JSX.Element}
 */
export default function BranchActionLine({ branchAction }) {
  const dispatch = useSettingsDispatch()
  const onClick = useCallback(() => {
    dispatch({
      type: "REMOVE_BRANCH_ACTION",
      payload: branchAction
    })
  }, [dispatch, branchAction])

  return (
    <div className="branchActionLine">
      <span>
        { branchAction }
      </span>
      <button onClick={onClick}>Delete</button>
    </div>
  )
}