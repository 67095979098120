import DataGrid from "../shared/DataGrid/DataGrid"
import Spinner from "react-bootstrap/Spinner"
import { useFetch } from "../hooks/useFetch"
import { get, post } from "../lib/api"
import { useSettings, useSettingsDispatch } from "../contexts/SettingsContext"
import { useMsal } from "@azure/msal-react"
import { useEffect, useMemo } from "react"
import { useDataGridDispatch } from "../shared/DataGrid/context/DataGridContext"
import { useGroups } from "../contexts/GroupsContext"
import useWebSocket from "react-use-websocket"

/**
 * Renders DataGrid. Relies on Districts, Settings, DataGrid contexts.
 * @returns {React.JSX.Element}
 */
export default function RecordContainer() {
  const { accounts } = useMsal()
  const userId = accounts[0].idTokenClaims.sub
  const { groups, activeDistrict } = useGroups()
  const { userSettings } = useSettings()
  const dataGridDispatch = useDataGridDispatch()
  const dispatch = useSettingsDispatch()
  const { sendJsonMessage, lastJsonMessage } = useWebSocket(process.env.REACT_APP_WS_URL, {
    share: true
  })

  const { 
    value: userProfile, 
    isLoading: userProfileIsLoading 
  } = useFetch(get, `user-profiles/${userId}`)

  const userProfiles = useMemo(() => userProfile ? userProfile.PROFILES : [], [userProfile])
  const activeProfile = useMemo(() => {
    return userSettings.activeProfile || 
    userProfiles.find(p => p.name === userSettings.activeProfile) || 
    userProfiles[userProfile?.DEFAULT_PROFILE || 0]
  }, [userProfile?.DEFAULT_PROFILE, userProfiles, userSettings.activeProfile])

  const group = useMemo(() => { 
    return groups.find(group => group.districts.some(district => district.shortName === activeDistrict?.shortName))
  }, [groups, activeDistrict?.shortName])

  useEffect(() => {  
    dispatch({
      type: "SET_PROFILES",
      payload: {
        profiles: [...userProfiles],
        defaultProfile: userProfile?.DEFAULT_PROFILE || 0
      }
    })
  }, [dispatch, userProfiles, userProfile])

  useEffect(() => {
    dispatch({
      type: "SET_ACTIVE_PROFILE",
      payload: {
        profile: activeProfile
      }
    })
  }, [dispatch, activeProfile])

  useEffect(() => {
    if (lastJsonMessage?.commentUpdate) {
      dataGridDispatch({ type: "UPDATE_RECORD_COMMENTS", payload: [lastJsonMessage.commentUpdate] })
    }
  }, [lastJsonMessage, dataGridDispatch])

  useEffect(() => {
    if (activeDistrict?.shortName) sendJsonMessage({
      type: "setDistrict",
      value: activeDistrict.shortName
    })
  }, [activeDistrict, sendJsonMessage])

  const { 
    value: records, 
    isLoading: recordsAreLoading 
  } = useFetch(post, "records-on-hold", JSON.stringify({ 
    district: activeDistrict, 
    role: group?.role 
  }))

  return (
    recordsAreLoading || userProfileIsLoading ? 
      <div className="loading">
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div> :
      <div className="recordContainer">
        {
          records && records.length > 0 ? 
            <DataGrid columns={activeProfile.columns} columnKey="key" rows={records} rowKey="id" cellKey="key" /> :
            <span>Please select an area from the selection box above.</span>
        }
      </div>
  )
}
