import { createContext, useContext, useReducer } from "react"

const GroupsContext = createContext(null)
const GroupsDispatchContext = createContext(null)

export function GroupsProvider({ children }) {
  const [ groups, dispatch ] = useReducer(groupsReducer, initialState)

  return (
    <GroupsContext.Provider value={groups}>
      <GroupsDispatchContext.Provider value={dispatch}>
        { children }
      </GroupsDispatchContext.Provider>
    </GroupsContext.Provider>
  )
}

export function useGroups() {
  return useContext(GroupsContext)
}

export function useGroupsDispatch() {
  return useContext(GroupsDispatchContext)
}

function groupsReducer(state, action) {
  switch(action.type) {
    case "SET_GROUPS":
      return {
        ...state,
        groups: action.payload,
        activeDistrict: action.payload[0]?.districts[0] || null
      }
    case "UPDATE_GROUP_CONFIG":
      return {
        ...state,
        groups: state.groups.map(group => {
          if (group.name === action.payload.name) {
            return action.payload
          } else {
            return group
          }
        })
      }
    case "SET_ACTIVE_DISTRICT": 
      return {
        ...state,
        activeDistrict: action.payload
      }
    default:
      return state
  }
}

const initialState = {
  groups: [],
  activeDistrict: null
}
