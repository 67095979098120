import { useMemo } from 'react'
import { useDataGrid } from '../shared/DataGrid/context/DataGridContext'
import AreaBoxLine from './AreaBoxLine'

/**
 * Renders AreaBoxLine for each area associated with active district.
 * Relies on Districts context.
 * @returns {React.JSX.Element}
 */
export default function AreaBox() {
  const { rows } = useDataGrid()
  const areas = useMemo(() => Array.from(new Set(rows.map(row => row.AREA_NAME))), [rows])

  return (
    <div title="District Areas" id="areaBox">
      {
        areas && areas.length > 0 ?
          areas.sort().map(area => (
            <AreaBoxLine key={area} name={area} />
          )) :
          <></>
      }
    </div>
  )
}