import { createContext, useContext, useReducer } from "react"

const SettingsContext = createContext(null)
const SettingsDispatchContext = createContext(null)

export function useSettings() {
  return useContext(SettingsContext)
}

export function useSettingsDispatch() {
  return useContext(SettingsDispatchContext)
}

/**
 * Provides Districts store context to child components.
 * @param {Object} obj
 * @param {FunctionComponentElement} obj.children
 * @returns {React.Context.Provider} 
 */
export function SettingsProvider({ children }) {
  const [ settings, dispatch ] = useReducer(settingsReducer, initialState)

  return (
    <SettingsContext.Provider value={settings}>
      <SettingsDispatchContext.Provider value={dispatch}>
        { children }
      </SettingsDispatchContext.Provider>
    </SettingsContext.Provider>
  )
}

/**
 * Creates new state objects based on incoming action.
 * @param {Object} state Existing state object.
 * @param {string} action Pre-defined action type.
 * @returns {Object} New state object.
 */
function settingsReducer(state, action) {
  switch(action.type) {
    case "TOGGLE_MODAL":
      return {
        ...state,
        [action.modal]: {
          ...state[action.modal],
          modalIsActive: !state[action.modal].modalIsActive
        }
      }
    case "SET_ACTIVE_PROFILE":
      return {
        ...state,
        userSettings: {
          ...state.userSettings,
          activeProfile: action.payload.profile
        }
      }
    case "SET_DEFAULT_PROFILE":
      return {
        ...state,
        userSettings: {
          ...state.userSettings,
          defaultProfile: action.payload
        }
      }
    case "SET_PROFILES":
      return {
        ...state,
        userSettings: {
          ...state.userSettings,
          profiles: action.payload.profiles,
          defaultProfile: action.payload.defaultProfile
        }
      }
    case "CREATE_PROFILE":
    case "DELETE_PROFILE":
    case "UPDATE_PROFILE":
      const activeProfileName = state.userSettings.activeProfile.name
      const activeProfile = action.payload.find(profile => profile.name === activeProfileName) || action.payload[0]
      return {
        ...state,
        userSettings: {
          ...state.userSettings,
          profiles: [...action.payload],
          activeProfile: activeProfile
        }
      }
    case "LOAD_GROUP_SETTINGS":
      return {
        ...state,
        groupSettings: {
          ...action.payload
        }
      }
    case "ADD_BRANCH_ACTION":
      return {
        ...state,
        groupSettings: {
          ...state.groupSettings,
          branchActions: [...state.groupSettings.branchActions, action.payload]
        }
      }
    case "REMOVE_BRANCH_ACTION":
      return {
        ...state,
        groupSettings: {
          ...state.groupSettings,
          branchActions: state.groupSettings.branchActions.filter(branchAction => branchAction !== action.payload)
        }
      }
    case "SET_PRIORITY_THRESHOLDS":
      return {
        ...state,
        groupSettings: {
          ...state.groupSettings,
          priorityThresholds: action.payload
        }
      }
    case "UPDATE_CUSTOM_ASSIGNMENT":
      return {
        ...state,
        groupSettings: {
          ...state.groupSettings,
          customLookups: state.groupSettings.customLookups.map((lookup, idx) => {
            if (idx === action.payload.idx) {
              return action.payload.lookup
            } else return lookup
          })
        }
      }
    case "ADD_CUSTOM_ASSIGNMENT":
      return {
        ...state,
        groupSettings: {
          ...state.groupSettings,
          customLookups: [...state.groupSettings.customLookups, action.payload]
        }
      }
    case "REMOVE_CUSTOM_ASSIGNMENT":
      return {
        ...state,
        groupSettings: {
          ...state.groupSettings,
          customLookups: state.groupSettings.customLookups.filter((lookup, idx) => idx !== action.payload)
        }
      }
    default:
      return state
  }
}

const initialState = {
  userSettings: {
    modalIsActive: false,
    activeProfile: null,
    defaultProfile: 0,
    profiles: [],
    profileEditor: {
      activeProfile: null
    }
  },
  userSchema: {
    modalIsActive: false,
    defaultSchema: [
      {
        ID: 1,
        ColumnName: "BRANCH_ID",
        Description: "Branch Id",
        Purpose: "",
        SampleData: "1514"
      },
      {
        ID: 2,
        ColumnName: "PAY_VENDOR_ID",
        Description: "Payment Vendor ID ",
        Purpose: "",
        SampleData: "10171"
      },
      {
        ID: 3,
        ColumnName: "PAY_VENDOR_NAME",
        Description: "Payment Vendor Name",
        Purpose: "",
        SampleData: "TRANE US INC PARTS"
      },
      {
        ID: 4,
        ColumnName: "AP_INVOICE_ID",
        Description: "Invoice Number",
        Purpose: "",
        SampleData: "51560015"
      },
      {
        ID: 5,
        ColumnName: "COMMENTS",
        Description: "Comments",
        Purpose: "",
        SampleData: ""
      },
      {
        ID: 6,
        ColumnName: "PO_ID",
        Description: "Purchase Order ID",
        Purpose: "",
        SampleData: "E1514-3692"
      },
      {
        ID: 7,
        ColumnName: "TERMS_DESC",
        Description: "Payment Terms Description",
        Purpose: "",
        SampleData: "NET 10TH PROX"
      },
      {
        ID: 8,
        ColumnName: "DISC_PCT_AMT",
        Description: "Discount Amount Percentage ",
        Purpose: "",
        SampleData: "2"
      },
      {
        ID: 9,
        ColumnName: "DISC_AMT",
        Description: "Discount Amount ",
        Purpose: "",
        SampleData: "4.52"
      },
      {
        ID: 10,
        ColumnName: "DATE_DIFFNUM",
        Description: "Date Difference Number",
        Purpose: "",
        SampleData: "30"
      },
      {
        ID: 11,
        ColumnName: "RCPT_IDS",
        Description: "Receipt Identification Number",
        Purpose: "",
        SampleData: "0"
      },
      {
        ID: 12,
        ColumnName: "TTL_RCVG_AMT",
        Description: "Received Amount - Total",
        Purpose: "",
        SampleData: "0"
      },
      {
        ID: 13,
        ColumnName: "INV_TOTAL_AMT",
        Description: "Total Invoice Amount",
        Purpose: "",
        SampleData: "240.23"
      },
      {
        ID: 14,
        ColumnName: "INV_FRT_AMT",
        Description: "Invoice Freight Amount",
        Purpose: "",
        SampleData: "14"
      },
      {
        ID: 15,
        ColumnName: "PAY_DUE_DATE",
        Description: "Payment Due Date",
        Purpose: "",
        SampleData: "2023-06-01T00:00:00"
      },
      {
        ID: 16,
        ColumnName: "AP_INV_DATE",
        Description: "Invoice Date",
        Purpose: "",
        SampleData: "2023-05-02T00:00:00"
      },
      {
        ID: 17,
        ColumnName: "INV_ENTRY_DATE",
        Description: "Invoice Entry Date",
        Purpose: "",
        SampleData: "2023-05-08T00:00:00"
      },
      {
        ID: 18,
        ColumnName: "WRITER_NAME",
        Description: "Writer Name",
        Purpose: "",
        SampleData: "William Roper"
      },
      {
        ID: 19,
        ColumnName: "AP_BATCH_CREATOR_NAME",
        Description: "AP Batch Creator Name",
        Purpose: "",
        SampleData: "Carey Barber"
      },
      {
        ID: 20,
        ColumnName: "DISCREP_DESCS",
        Description: "Discrepancy Description",
        Purpose: "",
        SampleData: "PO receiving not on file.\nAll Direct Invoices not processed."
      },
      {
        ID: 21,
        ColumnName: "PAY_VENDOR_ADDR1",
        Description: "Payment Vendor Address 1",
        Purpose: "",
        SampleData: "PO BOX 406469"
      },
      {
        ID: 22,
        ColumnName: "PAY_VENDOR_ADDR2",
        Description: "Payment Vendor Address 2",
        Purpose: "",
        SampleData: "ATLANTA, GA 30384-6469"
      },
      {
        ID: 23,
        ColumnName: "PO_VENDOR_ID",
        Description: "Purchase Vendor ID",
        Purpose: "",
        SampleData: "10171"
      },
      {
        ID: 24,
        ColumnName: "PO_VENDOR_ADDR1",
        Description: "Purchase Vendor Address 1",
        Purpose: "",
        SampleData: "PO BOX 406469"
      },
      {
        ID: 25,
        ColumnName: "PO_VENDOR_NAME",
        Description: "Purchase Vendor Name",
        Purpose: "",
        SampleData: "TRANE US INC PARTS"
      },
      {
        ID: 26,
        ColumnName: "PO_VENDOR_ADDR2",
        Description: "Purchase Vendor Address 2",
        Purpose: "",
        SampleData: "ATLANTA, GA 30384-6469"
      },
      {
        ID: 27,
        ColumnName: "ORDER_ID",
        Description: "Purchase Order ID",
        Purpose: "",
        SampleData: "404053"
      },
      {
        ID: 28,
        ColumnName: "DIR_INVOICES_ID",
        Description: "Direct Invoice Id",
        Purpose: "",
        SampleData: "404053"
      },
      {
        ID: 29,
        ColumnName: "DISCREP_FRT",
        Description: "Frieght Discrepancy",
        Purpose: "",
        SampleData: ""
      },
      {
        ID: 30,
        ColumnName: "RELEASE_CODE",
        Description: "Release Code",
        Purpose: "",
        SampleData: ""
      },
      {
        ID: 31,
        ColumnName: "PREVIEW_BATCH_DATE",
        Description: "Preview Batch Date",
        Purpose: "",
        SampleData: "2023-05-08T00:00:00"
      },
      {
        ID: 32,
        ColumnName: "PREVIEW_BATCH_ID",
        Description: "Preview Batch ID",
        Purpose: "",
        SampleData: "328716"
      },
      {
        ID: 33,
        ColumnName: "NUM_DAYS_ONHOLD",
        Description: "Number of Days on Hold",
        Purpose: "",
        SampleData: "1"
      },
      {
        ID: 34,
        ColumnName: "PurgedPos",
        Description: "Purged Purchase Orders",
        Purpose: "Derived",
        SampleData: " "
      },
      {
        ID: 35,
        ColumnName: "FREIGHT_AMT",
        Description: "Frieght Amount",
        Purpose: "",
        SampleData: "40"
      },
      {
        ID: 36,
        ColumnName: "PO_TERMS_COMMENT",
        Description: "Purchase Order Terms Comment",
        Purpose: "",
        SampleData: ""
      },
      {
        ID: 37,
        ColumnName: "SPTERMS",
        Description: "Special Terms",
        Purpose: "Derived",
        SampleData: " "
      },
      {
        ID: 38,
        ColumnName: "MISMATCHES",
        Description: "MisMatches",
        Purpose: "Derived",
        SampleData: "1"
      },
      {
        ID: 39,
        ColumnName: "ZEROAMT",
        Description: "Zero Amount",
        Purpose: "Derived",
        SampleData: " "
      },
      {
        ID: 40,
        ColumnName: "ZERORCPT",
        Description: "Zero Receipt",
        Purpose: "Derived",
        SampleData: " "
      },
      {
        ID: 41,
        ColumnName: "DEBITS",
        Description: "Debits",
        Purpose: "Derived",
        SampleData: " "
      },
      {
        ID: 42,
        ColumnName: "APCORRS",
        Description: "AP Correspondence",
        Purpose: "Derived",
        SampleData: " "
      },
      {
        ID: 43,
        ColumnName: "BRMISMATCH",
        Description: "Branch MisMatch",
        Purpose: "Derived",
        SampleData: " "
      },
      {
        ID: 44,
        ColumnName: "BR_REASST",
        Description: "Branch Re-Assessment",
        Purpose: "Derived",
        SampleData: " "
      },
      {
        ID: 45,
        ColumnName: "ALLSTOCK",
        Description: "All Stock",
        Purpose: "Derived",
        SampleData: " "
      },
      {
        ID: 46,
        ColumnName: "ALLDIRECTS",
        Description: "All Directs",
        Purpose: "Derived",
        SampleData: "X"
      },
      {
        ID: 47,
        ColumnName: "PO_TYPE",
        Description: "Purchase Order Type",
        Purpose: "",
        SampleData: "D"
      },
      {
        ID: 48,
        ColumnName: "SSC_PPDM_RPYCK",
        Description: "SSC_PPDM_RPYCK",
        Purpose: "Derived",
        SampleData: " "
      },
      {
        ID: 49,
        ColumnName: "REGION_NAME",
        Description: "Region Name",
        Purpose: "",
        SampleData: "Blended Central"
      },
      {
        ID: 50,
        ColumnName: "AREA_NAME",
        Description: "Area Name",
        Purpose: "",
        SampleData: "C Blended Ohio North Area"
      },
      {
        ID: 51,
        ColumnName: "SSC_OK_OD",
        Description: "SSC_OK_OD",
        Purpose: "Derived",
        SampleData: " "
      },
      {
        ID: 52,
        ColumnName: "SSC_OTHER",
        Description: "SSC_OTHER",
        Purpose: "Derived",
        SampleData: " "
      },
      {
        ID: 53,
        ColumnName: "DISTRICT_NAME",
        Description: "District Name",
        Purpose: "",
        SampleData: "D12 C Blended Ohio Valley"
      },
      {
        ID: 54,
        ColumnName: "AP_OPEN_ACCT",
        Description: "Accounts Payable Open Account",
        Purpose: "",
        SampleData: "OHVAL"
      },
      {
        ID: 55,
        ColumnName: "AP_TO_PO_XREF_KEY",
        Description: "AP Key field",
        Purpose: "",
        SampleData: "OHVAL*10171*51560015"
      },
      {
        ID: 56,
        ColumnName: "TODAYSDATE",
        Description: "Todays Date",
        Purpose: "",
        SampleData: "2023-05-09T16:25:39.813"
      },
      {
        ID: 57,
        ColumnName: "DAYSTOPAY",
        Description: "Days to Pay",
        Purpose: "Payment Due date - Current Date",
        SampleData: "-23"
      },
      {
        ID: 58,
        ColumnName: "DAYSTOPAYHOLD",
        Description: "Days to Pay On Hold",
        Purpose: "Payment Due date - Current Date",
        SampleData: "-23"
      },
      {
        ID: 59,
        ColumnName: "PRIORITY",
        Description: "Priority",
        Purpose: "Derived",
        SampleData: "HIGH"
      },
      {
        ID: 60,
        ColumnName: "ASSIGNEDTO",
        Description: "Assigned To",
        Purpose: "Derived",
        SampleData: " "
      },
      {
        ID: 61,
        ColumnName: "ORDER_KEY",
        Description: "Order Key Field",
        Purpose: "Derived",
        SampleData: "OHVAL*0404053"
      },
      {
        ID: 62,
        ColumnName: "ORDER_ACCT",
        Description: "Order Account Key Field",
        Purpose: "Derived",
        SampleData: "OHVAL"
      }
     ]},
  groupSettings: {
    modalIsActive: false,
    priorityThresholds: {
      highPriority: {
        greaterThanOrEqualTo: 0,
        lessThanOrEqualTo: 15
      },
      mediumPriority: {
        greaterThanOrEqualTo: 16,
        lessThanOrEqualTo: 30
      }
    },
    branchActions: [],
    customLookups: []
  }
}
