import { useCallback, useState } from "react"
import { useDataGrid } from "../../shared/DataGrid/context/DataGridContext"
import { useSettingsDispatch } from "../../contexts/SettingsContext"

/**
 * @typedef {Object} CustomAssignment
 * @property {string} column Key of lookup column.
 * @property {string} value Value to match.
 * @property {string} assignTo Value to display in "Send Comment To" column.
 */

/**
 * Renders a line to update a configured custom assignment in District Settings.
 * Relies on Settings Dispatch.
 * @param {Object} props 
 * @param {CustomAssignment} props.customAssignment Related custom assignment.
 * @param {number} props.idx Index of custom assignment in memory.
 * @returns {React.JSX.Element}
 */
export default function CustomAssignmentLine({ customAssignment, idx }) {
  const dispatch = useSettingsDispatch()
  const [ fieldValues, setFieldValues ] = useState({
    column: customAssignment.column,
    value: customAssignment.value,
    assignTo: customAssignment.assignTo
  })

  // Update custom assignment in memory when field loses focus
  const onBlur = useCallback(() => {
    dispatch({
      type: "UPDATE_CUSTOM_ASSIGNMENT",
      payload: {
        idx: idx,
        lookup: {
          ...fieldValues,
          value: fieldValues.column === "BRANCH_ID" && fieldValues.value !== "" ? parseInt(fieldValues.value) : fieldValues.value
        }
      }
    })
  }, [dispatch, idx, fieldValues])

  // Update state field values
  const onChange = useCallback(event => {
    setFieldValues({
      ...fieldValues,
      [event.target.dataset.key]: event.target.value
    })
  }, [fieldValues])

  // Delete custom assignment from store
  const onClick = useCallback(() => {
    dispatch({
      type: "REMOVE_CUSTOM_ASSIGNMENT",
      payload: idx
    })
  }, [dispatch, idx])

  // Restricts columns available to match against
  const { columns } = useDataGrid()

  const eligibleColumns = columns.filter(column => ["BRANCH_ID", "PAY_VENDOR_ID", "WRITER_NAME"].includes(column.key))

  return (
    <div className="customAssignmentLine">
      <select data-key="column" onChange={onChange} onBlur={onBlur} value={fieldValues.column}>
        {
          eligibleColumns.map(column => <option key={column.key} value={column.key}>{column.displayName}</option>)
        }
      </select>
      <input data-key="value" type="text" onChange={onChange} onBlur={onBlur} value={fieldValues.value}></input>
      <input data-key="assignTo" type="text" onChange={onChange} onBlur={onBlur} value={fieldValues.assignTo}></input>
      <button onClick={onClick}>Delete</button>
    </div>
  )
}