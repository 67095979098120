import { useSettings } from "../contexts/SettingsContext"
import ScenarioButton from "./ScenarioButton"

/**
 * Renders a ScenarioButton for Priority.
 * Relies on district settings priority thresholds.
 * @returns {React.JSX.Element}
 */
export default function PriorityBox() {
  const { groupSettings } = useSettings()
  const { priorityThresholds } = groupSettings

  const scenarios = [
    { 
      name: "High", 
      apply: function(record) {
        const { highPriority } = priorityThresholds
        const { greaterThanOrEqualTo, lessThanOrEqualTo } = highPriority
        return record.DAYS_TO_PAY < 0 || 
          (record.DAYS_TO_PAY >= greaterThanOrEqualTo && record.DAYS_TO_PAY <= lessThanOrEqualTo)
      }
    }, 
    { 
      name: "Medium",
      apply: function(record) {
        const { mediumPriority } = priorityThresholds
        const { greaterThanOrEqualTo, lessThanOrEqualTo } = mediumPriority
        return record.DAYS_TO_PAY >= greaterThanOrEqualTo && record.DAYS_TO_PAY <= lessThanOrEqualTo
      } 
    }
  ]

  return (
    <div id="priorityBox" className="buttonBox">
      { scenarios.map(scenario => (
          <ScenarioButton 
            key={scenario.name} 
            scenario={scenario}
            filterKey={"DAYS_TO_PAY"} 
            category={"priority"}
            displayName={"Priority"}
            apply={scenario.apply}
            />
        )) }
    </div>
  )
}
