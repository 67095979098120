import { useMsal } from "@azure/msal-react"
import { useState, useMemo } from "react"
import ScenarioButton from "./ScenarioButton"

export default function ScenarioBox() {
  const { accounts } = useMsal()
  const [ expandIsChecked, setExpandIsChecked ] = useState(false)

  const defaultScenarios = [
    { 
      name: "Purged PO",
      key: "PurgedPos",
      apply: function(record) {
        return record.COMMENTS?.includes("PURGED PO")
      }
    }, 
    { 
      name: "SP Terms",
      key: "SPTERMS",
      apply: function(record) {
        return record.PO_TERMS_COMMENT !== null
      } 
    }, 
    { 
      name: "Zero Receipt" ,
      key: "ZERORCPT",
      apply: function(record) {
        const { INV_FRT_AMT, INV_TOTAL_AMT, TTL_RCVG_AMT } = record
        const diff = INV_TOTAL_AMT - INV_FRT_AMT
        return TTL_RCVG_AMT !== 0 && TTL_RCVG_AMT - 100 <= diff && diff <= TTL_RCVG_AMT + 100
      }
    }, 
    { 
      name: "Branch Reassessment",
      key: "BR_REASST",
      apply: function(record) {
        return record.BR_REASST === "X"
      }
    },
    { 
      name: "Freight",
      key: "FREIGHT",
      apply: function(record) {
        return ["11", "13"].includes(record.DISCREP_FRT) && !record.RELEASE_CODE?.includes("FP")
      } 
    }, 
    { 
      name: "Vendor Mismatch",
      key: "VENDOR_MISMATCH",
      apply: function(record) {
        return record.MISMATCHES?.includes("4") &&
          !record.MISMATCHES?.includes("24") &&
          !record.RELEASE_CODE?.includes("VW")
      } 
    }, 
    { 
      name: "Debit",
      key: "DEBIT",
      apply: function(record) {
        return record.COMMENTS?.includes("SSC DEBIT")
      } 
    }, 
    { 
      name: "PBDM_RPYCK",
      key: "PBDM_RPYCK",
      apply: function(record) {
        return (record.AP_INVOICE_ID?.includes("PBDM") || record.AP_INVOICE_ID?.includes("RPYCK") ) &&
          !record.RELEASE_CODE?.includes("PB")
      } 
    },
    { 
      name: "Branch Mismatch",
      key: "BRANCH_MISMATCH",
      apply: function(record) {
        return record.MISMATCHES?.includes("5") && !record.MISMATCHES?.includes("25")
      } 
    }, 
    { 
      name: "$0 Invoices",
      key: "ZERO_INVOICES",
      apply: function(record) {
        return record.INV_TOTAL_AMT === 0
      }
    }, 
    { 
      name: "AP Correction",
      key: "AP_CORRECTION",
      apply: function(record) {
        return record.COMMENTS?.includes("SSC AP CORRECTION")
      } 
    },
    { 
      name: "Backorder Directs",
      key: "BACKORDER_DIRECTS",
      apply: function(record) {
        return record.COMMENTS?.includes("*BACKORDER")
      }
    }
  ]
  const distPipeScenarios = [
    {
      name: "Pending BOL",
      key: "PendingBOL",
      apply: function(record) {
        return record.COMMENTS?.includes("PENDING BOL")
      }
    },
    {
      name: "VSDF",
      key: "VSDF",
      apply: function(record) {
        return record.COMMENTS?.includes("VSDF")
      }
    },
    {
      name: "DC Operations",
      key: "DCOperations",
      apply: function(record) {
        return record.COMMENTS?.includes("DC OPERATIONS")
      }
    },
    {
      name: "Returns",
      key: "Returns",
      apply: function(record) {
        return record.COMMENTS?.includes("RETURNS")
      }
    },
    {
      name: "Buying Team",
      key: "BuyingTeam",
      apply: function(record) {
        return record.COMMENTS?.includes("BUYING TEAM")
      }
    }
  ]
  const sscScenarios = [
    {
      name: "SSC Other",
      key: "SSCOther",
      apply: function(record) {
        return record.COMMENTS?.includes("SSC OTHER")
      }
    },
    {
      name: "SSC OK/OD",
      key: "SSCOkOd",
      apply: function(record) {
        return record.COMMENTS?.includes("SSC RELEASE OK/OD")
      }
    }
  ]

  const isDistPipe = useMemo(() => accounts[0].idTokenClaims.roles.some(role => role.match(/DIST|PIPE|SSC/g)), [accounts])
  const isSSC = useMemo(() => accounts[0].idTokenClaims.roles.some(role => role.match(/SSC/g)), [accounts])

  const renderedScenarios = []

  if (isSSC) {
    if (expandIsChecked) {
      renderedScenarios.push(...sscScenarios, ...defaultScenarios, ...distPipeScenarios)
    } else {
      renderedScenarios.push(...sscScenarios, ...defaultScenarios)
    }
  } else if (isDistPipe) {
    if (expandIsChecked) {
      renderedScenarios.push(...distPipeScenarios, ...defaultScenarios)
    } else {
      renderedScenarios.push(...distPipeScenarios)
    }
  } else {
    renderedScenarios.push(...defaultScenarios)
  }

  return (
    <>
      <div id="scenarioBox" className="buttonBox">
        { renderedScenarios.map(scenario => (
          <ScenarioButton 
            key={scenario.name} 
            scenario={scenario} 
            filterKey={"SCENARIO"}
            category={"scenario"}
            displayName={"Scenario"}
            apply={scenario.apply}
            />
          )) }
      </div>
      {
        isSSC || isDistPipe ?
          <label>
            Display Additional Scenarios: 
            <input type="checkbox" checked={expandIsChecked} onChange={(e) => setExpandIsChecked(e.target.checked)}></input>
          </label> :
          <></>
      }
    </>
    
  )
}
