import ScenarioButton from "./ScenarioButton"

/**
 * Renders a ScenarioButton for PO Type.
 * @returns {React.JSX.Element}
 */
export default function POTypeBox() {
  const scenarios = [
    { 
      name: "All Stocks",
      apply: function(record) {
        return record.PO_TYPE === "S"
      } 
    }, 
    { 
      name: "All Directs",
      apply: function(record) {
        return record.PO_TYPE === "D"
      }
    }, 
    { 
      name: "Blanks*",
      apply: function(record) {
        return record.PO_TYPE === null
      } 
    }
  ]

  return (
    <div id="poTypeBox" className="buttonBox">
      { scenarios.map(scenario => 
          <ScenarioButton 
            key={scenario.name} 
            scenario={scenario} 
            filterKey={"PO_TYPE"}
            category={"poType"}
            displayName={"PO Type"}
            apply={scenario.apply}
            />) }
    </div>
  )
}
