import { useDataGridDispatch } from "../shared/DataGrid/context/DataGridContext"
import { useCallback } from "react"

export default function ResetFiltersButton() {
  const dispatch = useDataGridDispatch()
  const onClick = useCallback(() => {
    dispatch({
      type: "RESET_ALL_FILTERS"
    })
  }, [dispatch])
  return (
    <button onClick={onClick} id="resetAllFiltersButton">
      RESET ALL FILTERS
    </button>
  )
}