import { useDataGrid, useDataGridDispatch } from "./context/DataGridContext"
import { useCallback, useMemo, useState } from "react"

export default function DataGridHeaderCellFilterOptions({ column, onMouseLeave }) {
  const { rows, filters, activeSort, activeSortDirection } = useDataGrid()
  const dispatch = useDataGridDispatch()
  const [ sortAsc, setSortAsc ] = useState(column.key === activeSort && activeSortDirection === "asc")
  const [ sortDes, setSortDes ] = useState(column.key === activeSort && activeSortDirection === "des")

  // Narrow filter options based on applied filters, excluding current filter

  const filteredOptions = useMemo(() => {
    const eligibleFilters = filters.filter(filter => filter.key !== column.key)
    const options = rows.filter(row => eligibleFilters.every(filter => filter.apply(row, filter.values)))
    if (column.key === "COMMENTS") return ["(blank)"]
    if (column.key === "COMMENT_LAST_UPDATED") {
      const records = options.filter(opt => !!opt.COMMENT_LAST_UPDATED)
      const formattedDates = records
        .sort((a, b) => a.COMMENT_LAST_UPDATED - b.COMMENT_LAST_UPDATED)
        .map(date => new Date(date.COMMENT_LAST_UPDATED * 1000).toLocaleDateString())
      return Array.from(new Set(["(blank)", ...formattedDates]))
    }
    return createSortedFilteredOptions(options, column.key)
  }, [rows, filters, column.key])

  // Dispatch filter addition or removal to DataGrid store
  
  const onChange = useCallback(event => {
    let value
    if (event.target.dataset.value === "selectall") {
      value = "selectall"
    } else {
      if ([
        "BRANCH_ID", 
        "DAYSTOPAY", 
        "DISC_PCT_AMT", 
        "DISC_AMT",
        "DATE_DIFFNUM",
        "TTL_RCVG_AMT",
        "INV_TOTAL_AMT",
        "INV_FRT_AMT",
        "NUM_DAYS_ONHOLD"
      ].includes(column.key)) {
        value = event.target.dataset.value === "(blank)" ?
          event.target.dataset.value : parseFloat(event.target.dataset.value)
      } else {
        value = event.target.dataset.value
      }
    }
    
    let actionType
    if (event.target.checked) {
      actionType = value === "selectall" ? "ADD_BULK_FILTERS" : "ADD_FILTER"
    } else {
      actionType = value === "selectall" ? "REMOVE_BULK_FILTERS" : "REMOVE_FILTER"
    }
      
    dispatch({
      type: actionType,
      payload: {
        category: "column",
        key: column.key,
        displayName: column.name,
        [value === "selectall" ? "values" : "value"]: value === "selectall" ? filteredOptions : value,
        apply: function (record, values) {
          let search = isBlank(record[column.key]) ? "(blank)" : record[column.key]
          if (column.key === "COMMENT_LAST_UPDATED") {
            if (search === "(blank)") {
              return values.includes("(blank)")
            } else {
              return values.includes(new Date(search * 1000).toLocaleDateString())
            }
          }
          return values.map(val => val.toString()).includes(search.toString())
        }
      }
    })

  }, [dispatch, filteredOptions, column.key, column.name])

  const onSortChange = useCallback(event => {
    const { direction } = event.target.dataset
    setSortAsc(direction === "asc")
    setSortDes(direction === "des")

    dispatch({
      type: "SORT_ROWS",
      payload: {
        prop: column.key,
        direction: event.target.dataset.direction
      }
    })
  }, [dispatch, column.key])


  return (
    <div className="data-grid-header-cell-filter-options" onMouseLeave={onMouseLeave}>
      Sort: 
      <br />
      <label>
        <input type="radio" onChange={onSortChange} data-direction="asc" checked={sortAsc}></input>{""}A to Z
      </label>
      <label>
        <input type="radio" onChange={onSortChange} data-direction="des" checked={sortDes}></input>{""}Z to A
      </label>
      <hr />
      Filter:
      <br />
      <label key="selectall">
        <input onChange={onChange} type="checkbox" data-value="selectall" checked={
          !!filters.find(f => f.key === column.key && f.values.length === filteredOptions.length)
        }></input>
        Select All
      </label>
      {
        filteredOptions.map(option => {
          return (
            <label key={option}>
              <input onChange={onChange} type="checkbox" data-value={option} checked={
                !!filters.find(f => f.key === column.key && f.values.map(val => val.toString()).includes(option?.toString()))
                }></input>
              {
                ["DISC_AMT", "TTL_RCVG_AMT", "INV_TOTAL_AMT", "INV_FRT_AMT"].includes(column.key) && option !== "(blank)" ? 
                  `$${parseFloat(option).toFixed(2)}` : option
              }
            </label>
          )
        })
      }
    </div>
  )
}

function createSortedFilteredOptions(options, key) {
  const values = options.map(option => option[key])
  const hasBlank = values.some(value => isBlank(value))
  const actualSortedValues = values.filter(value => !isBlank(value)).sort((a, b) => {
    let aVal = a, bVal = b
    const numberFields = [
      "BRANCH_ID", "PAY_VENDOR_ID", "DAYSTOPAY", "DISC_AMT",
      "DATE_DIFFNUM", "TTL_RCVG_AMT", "INV_TOTAL_AMT", "INV_FRT_AMT",
      "PO_VENDOR_ID", "ORDER_ID", "DIR_INVOICES_ID", "NUM_DAYS_ONHOLD"
    ]
    const dateFields = [
      "PAY_DUE_DATE", "AP_INV_DATE", "INV_ENTRY_DATE"
    ]
    if (numberFields.includes(key)) { 
      aVal = !isNaN(parseFloat(a)) ? parseFloat(a) : null 
      bVal = !isNaN(parseFloat(b)) ? parseFloat(b) : null
    }
    if (dateFields.includes(key)) { 
      aVal = !isNaN(Date.parse(a)) ? Date.parse(a) : null 
      bVal = !isNaN(Date.parse(b)) ? Date.parse(b) : null 
     }
    if (aVal != null && bVal != null && aVal > bVal) return 1
    if (aVal != null && bVal != null && bVal > aVal) return -1
    if (aVal != null && bVal == null) return 1
    if (bVal != null && aVal == null) return -1
    return 0
  })
  const uniqueValues = new Set(actualSortedValues)
  const sortedFilteredOptions = []
  if (hasBlank) sortedFilteredOptions.push("(blank)")
  sortedFilteredOptions.push(...uniqueValues)
  return sortedFilteredOptions
}

function isBlank(value) {
  return ["", " ", null, undefined].includes(value)
}