import BranchActions from "../components/GroupSettings/BranchActions";
import CustomAssignments from "../components/GroupSettings/CustomAssignments";
import PriorityThresholds from "../components/GroupSettings/PriorityThresholds";
import SaveGroupSettingsButton from "../components/GroupSettings/SaveGroupSettingsButton";
import ModalContainer from "./ModalContainer";

export default function GroupSettingsModal({ show }) {
  return (
    <ModalContainer 
      show={show} 
      type="groupSettings" 
      title="Group Settings"
      footer={<SaveGroupSettingsButton />}>
      <div id="groupSettingsContainer">
        <div>
          <PriorityThresholds />
          <BranchActions />
        </div>
        <div>
          <CustomAssignments />
        </div>
      </div>
    </ModalContainer>
  )
}