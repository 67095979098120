import { useCallback, useMemo } from "react"
import { useDataGrid, useDataGridDispatch } from "../shared/DataGrid/context/DataGridContext"

/**
 * Renders label and checkbox input for provided area.
 * Relies on DataGrid filters and dispatch contexts.
 * @param {Object} obj
 * @param {string} obj.name Name of area.
 * @returns {React.JSX.Element}
 */
export default function AreaBoxLine({ name }) {
  const { filters } = useDataGrid()
  const dispatch = useDataGridDispatch()

  // Add or remove filter based on checked
  const onChange = useCallback(event => {
    if (event.target.tagName !== "INPUT") return
    dispatch({
      type: event.target.checked ? "ADD_FILTER" : "REMOVE_FILTER",
      payload: {
        category: "area",
        key: "AREA_NAME",
        displayName: "Area",
        value: event.target.dataset.key,
        apply: function(record, values) {
          return values.includes(record["AREA_NAME"])
        }
      }
    })
  }, [dispatch])

  const formattedName = useMemo(() => {
    return name.replace(/^([CWE] Blended )|^(WW )|^(FFF )| Area$/g, "")
  }, [name])

  return (
    <label>
      <input onChange={onChange} type="checkbox" checked={
        // Set checked equal to if area is included in currently applied filters
        !!filters.find(f => f.key === "AREA_NAME" && f.values.includes(name))
      } data-key={name} />
      { formattedName }
    </label>
  )
}
