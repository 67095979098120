import ColumnSettings from "../components/UserSettings/ColumnSettings";
import { ProfileEditorProvider } from "../contexts/ProfileEditorContext";
import ModalContainer from "./ModalContainer";

/**
 * Renders UserSettings ModalContainer, ColumnSettings.
 * Relies on ProfileEditor context.
 * @param {Object} obj
 * @param {boolean} obj.show 
 * @returns {React.JSX.Element}
 */
export default function UserSettingsModal({ show }) {
  return (
    <ProfileEditorProvider>
      <ModalContainer show={show} type="userSettings" title="User Settings">
        <ColumnSettings />
      </ModalContainer>
    </ProfileEditorProvider>
  )
}