import { useCallback, useMemo } from "react"
import { useSettingsDispatch } from "../contexts/SettingsContext"
import { useDataGridDispatch } from "../shared/DataGrid/context/DataGridContext"
import { useGroups, useGroupsDispatch } from "../contexts/GroupsContext"

/**
 * Renders a select box with all allowed districts.
 * Relies on Districts and Settings contexts.
 * @returns {React.JSX.Element}
 */
export default function DistrictBox() {
  const dataGridDispatch = useDataGridDispatch()
  const groupsDispatch = useGroupsDispatch()
  const settingsDispatch = useSettingsDispatch()
  const { groups } = useGroups()

  const districts = useMemo(() => groups.map(group => [...group.districts.map(district => district.shortName)]).flat(), [groups])

  // Update selected district, activate selected district configuration
  const onChange = useCallback(event => {
    dataGridDispatch({ type: "RESET_ALL_FILTERS" })
    const group = groups.find(group => group.districts.map(district => district.shortName).includes(event.target.value))
    const district = group.districts.find(district => district.shortName === event.target.value)
    groupsDispatch({ type: "SET_ACTIVE_DISTRICT", payload: district })
    const { config } = group
    settingsDispatch({ type: "LOAD_GROUP_SETTINGS", payload: config })
  }, [settingsDispatch, dataGridDispatch, groupsDispatch, groups])

  return (
    <div id="districtBox">
        {
          districts.length > 1 ?
            <select onChange={onChange} className="districtBox-select">
              {districts.map(district => <option key={district} value={district}>{district}</option>)}
            </select> :
            <span className="districtBox-select">
              {districts[0] || ""}          
            </span>
        }
    </div>
  )
}
