import { useState, useEffect } from "react"
import { useMsal } from "@azure/msal-react"

/**
 * @typedef {Object} FetchResponse
 * @property {Object|null} value Response parsed as JSON, or null.
 * @property {Error|null} error Thrown error, or null.
 * @property {boolean} isLaoding Whether fetch has completed or not. 
 */

/**
 * Invokes an API call using the provided helper function, endpoint, and optional request payload.
 * Parses the result as JSON.
 * @param {function} method Helper function.
 * @param {string} endpoint API endpoint.
 * @param {string} data Optional payload.
 * @returns {FetchResponse}
 */
export function useFetch(method, endpoint, data) {
  const [ value, setValue ] = useState(null)
  const [ error, setError ] = useState(null)
  const [ isLoading, setIsLoading ] = useState(true)
  const { instance, accounts } = useMsal()

  useEffect(() => {
    async function invokeFetch() {
      try {
        setIsLoading(true)
        const tokenRequest = { account: accounts[0] }
        const tokenResponse = await instance.acquireTokenSilent(tokenRequest)
        const result = await method(endpoint, tokenResponse.idToken, data)
        const json = await result.json()
        setValue(json)
      } catch (error) {
        console.log(error)
        setError(error)
      } finally {
        setIsLoading(false)
      }
    }

    invokeFetch()
  }, [accounts, instance, method, endpoint, data])

  return { value, error, isLoading }
}