import { useSettings } from "../../contexts/SettingsContext"
import { useProfileEditor, useProfileEditorDispatch } from "../../contexts/ProfileEditorContext"
import ColumnSettingsLine from "./ColumnSettingsLine"
import ColumnSettingsButtons from "./ColumnSettingsButtons"
import ColumnProfileLine from "./ColumnProfileLine"
import { useEffect, useMemo, useRef } from "react"

/**
 * Renders column editor for active profile in Profile Editor.
 * Relies on Profile Editor context.
 * @returns {React.JSX.Element}
 */
export default function ColumnSettings() {
  const { userSettings } = useSettings()
  const { editorActiveProfile: editorProfile, editorColumns } = useProfileEditor()
  const dragSource = useRef()
  const dispatch = useProfileEditorDispatch()

  // Set editor profile
  const editorActiveProfile = useMemo(() => editorProfile || userSettings.activeProfile, [editorProfile, userSettings])

  useEffect(() => {
    dispatch({
      type: "INITIALIZE_EDITOR",
      payload: {
        editorActiveProfile: editorActiveProfile,
        editorColumns: editorActiveProfile.columns
      }
    })
  }, [dispatch, editorActiveProfile])

  return (
    <div id="columnSettings">
      <div>
        <h2>Columns</h2>
        <div>
          <div className="columnSettingsLine">
            <span></span>
            <span>Column Name</span>
            <span>Display Name</span>
            {/* <span>Show/Hide</span> */}
            <span>Freeze</span>
            {/* <span>Width</span> */}
          </div>
          {
            editorColumns.map(column => <ColumnSettingsLine key={column.key} dragSource={dragSource} column={column} />)
          }
        </div>
        <ColumnSettingsButtons />
      </div>
      <div>
        <h2>Custom Profiles</h2>
        <div>
          {
            userSettings.profiles.map(profile => <ColumnProfileLine key={profile.name} profile={profile} />)
          }
        </div>
      </div>
    </div>
  )
}